import React from "react";
import { graphql } from "gatsby";
import SEO from "Components/SEO";
import Legal from "../features/Legal";

class LegalDocument extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const document = data?.sanityLegal;
    const documents = pageContext?.documents;

    return (
      <>
        <SEO title="Legal" />
        <Legal documents={documents} document={document} />
      </>
    );
  }
}

export default LegalDocument;

export const pageQuery = graphql`
  query LegalQueryByTitle($title: String!) {
    sanityLegal(title: { eq: $title }) {
      _updatedAt
      _id
      title
      slug {
        current
      }
      _rawText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
