import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { format, parseISO } from "date-fns";
import styles from "./legalContent.module.scss";
import XupFooter from "Components/XupFooter";

export default function Content({ title, updatedAt, content }) {
  return (
    <main className={styles.container}>
      <h1>{title}</h1>
      <div className={styles.updatedAt}>{`Updated on ${format(parseISO(updatedAt), "MMMM do yyyy")}`}</div>
      <div style={{ paddingBottom: "40px" }}>
        <BlockContent blocks={content} />
      </div>
      <XupFooter />
    </main>
  );
}
