import React from "react";
import cx from "classnames";
import { Link, navigate, navigateTo } from "gatsby";
import Content from "./Content";
import Dropdown from "react-dropdown";

import useWindowWidth from "Hooks/useWindowWidth";
import BackArrowButton from "Components/BackArrowButton";
import { FeedbackSocial } from "../../features/Home/BrandAside/index";
import FeedbackButton from "Components/FeedbackButton";
import XupFooter from "Components/XupFooter";
import XupWordmark from "Svg/XupBetaWordmark";
import Chevron from "Svg/Chevron";
import Dismiss from "Svg/Dismiss";
import styles from "./legal.module.scss";
import "react-dropdown/style.css";

import { document, console } from "window-or-global";

function Legal({ documents, document = {} }) {
  let width = useWindowWidth();
  const options = ["Privacy Policy", "Terms of Service"];
  const defaultOption = options[0];

  const elem0 = React.useRef();
  const elem1 = React.useRef();

  function _onSelect(options) {
    // document.getElementById('PrivacyPolicy')
    // document.getElementById('TermsofService')
    if (options.value === "Privacy Policy") {
      return navigateTo("/legal/privacy-policy");
    }

    if (options.value === "Terms of Service") {
      return navigateTo("/legal/terms-of-service");
    } else {
    }
  }

  const arrowClosed = <Chevron className="arrow-open" />;
  const arrowOpen = <Dismiss className="arrow-closed" />;

  return (
    <>
      <aside className={styles.aside}>
        <div className={styles.container}>
          <section className={styles.title}>
            <BackArrowButton />
            <h1>Legal Shit</h1>
          </section>
          <section className={styles.options}>
            <p className={styles.p}>
              {documents.map((doc) => (
                <Link
                  to={doc.path}
                  className={cx({
                    [styles.activeLink]: doc.title === document?.title,
                  })}
                  id={doc.title.replace(/\s/g, "")}
                >
                  {doc.title}
                </Link>
              ))}
            </p>
          </section>
          {width < 1024 && (
            <Dropdown
              onChange={_onSelect}
              className={styles.dropdown}
              controlClassName={styles.dropdownControl}
              menuClassName={styles.menuItems}
              options={options}
              value={document?.title}
              placeholder="Select an option"
              arrowOpen={arrowOpen}
              arrowClosed={arrowClosed}
              arrowClassName="arrow"
            />
          )}

          <section className={styles.feedbackContainer}>{width > 1024 && <FeedbackSocial />}</section>
        </div>
      </aside>
      <Content content={document?._rawText} title={document?.title} updatedAt={document._updatedAt} />
    </>
  );
}

export default Legal;
